<template>
 <div :class="windowClass">
   <v-container>
   
  
    <v-row>
      <v-col cols="12">
        

               <v-toolbar dense elevation="0" class="mt-4 mb-4" >
                <span style="width: 240px;" class="mr-2">
                 <v-select
                 outlined
                 label="Filter"
                 dense
                 hide-details
                 v-model="filterText"
                 :items="['Name', 'Adults','Occupation/Skills','Senior Citizen', 'SK Members', 'Employed', 'Unemployed',  '4P Members',  'Phil Health Members']"
                 />
                 </span>
                 <span style="width: 100%;">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                 </span>
               </v-toolbar> 
               <v-btn small @click="dlToCsv()" v-if="FILTERDATA.length>0"><v-icon>mdi-download</v-icon>Download CSV</v-btn>
                  <v-divider class="mt-4 mb-4"/>
                  <table class="va-table text-body-2" id="to-dl-table">
                    <tr>
                      <th>NO.</th>
                        <th>NAME</th>
                        <th>GENDER</th>
                        <th>CIVIL STATUS</th>
                        <th>OCCUPATION/SKILLS</th>
                        <th>REMARKS</th>
                    </tr>
                    <tr v-for="(item,index) in FILTERDATA" :key="index">
                      <td class="text-left">{{ index+1 }}</td>
                            <td class="text-left">{{ item.surname }}, {{ item.first_name }}</td>
                            <td class="text-left">{{ item.gender }}</td>
                            <td class="text-left">{{ item.civil_status }}</td>
                            <td class="text-left">{{ item.occupation }} {{ item.skills?"(" +item.skills+")":"" }}</td>
                            <td class="text-left">{{ remarks(item) }}</td>
                         
                    </tr>
                    <tr>
                      <td colspan="6" class="font-italic">Filter: {{filterText}} | {{FILTERDATA.length}} Found(s)</td>
                    </tr>
                  </table>
      </v-col>
    </v-row>
    </v-container>
    <va-census-form :show="form" @DialogEvent="formEv"/>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        search: '',
        items:[],
      
        filterText: "Name"
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getItems()
          this.filterText = "Name"
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      FILTERDATA() {
        //['All', 'Adults', 'Senior Citizen', 'SK Members', 'Employed', 'Unemployed']
        switch(this.filterText) {
            case 'Adults':
              return this.Adults
            case 'Senior Citizen':
              return this.Seniors
            case 'SK Members':
              return this.SKs
            case 'Employed':
              return this.Employed
            case 'Unemployed':
              return this.Unemployed
            case 'Phil Health Members':
              return this.PhMember  
            case '4P Members':
              return this.PPPPMember
            case 'Name':
              return this.FilterName 
            case 'Occupation/Skills':
              return this.FilterSkills                
            default:
                return this.items
        }
      },
      Adults() {
        var filters = this.items.reduce((res,item)=>{
            if("Adults" == item.category) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      FilterSkills(){
        var itemArr = []
        if(this.search !=''){
          if(this.search.length>2) {
           
            itemArr = this.items.reduce((res,item)=>{
              let fullname = item.occupation +" "+ item.skills
              console.log(this.search, fullname)
              let index = fullname.search(new RegExp(this.search, 'i'));
                if(index != -1) {
                res.push(item)
              }
              return res
            },[]) 
          }
        } 
        return itemArr
      },
      FilterName(){
        var itemArr = []
        if(this.search !=''){
          if(this.search.length>2) {
           
            itemArr = this.items.reduce((res,item)=>{
              let fullname = item.first_name +" "+ item.surname
              console.log(this.search, fullname)
              let index = fullname.search(new RegExp(this.search, 'i'));
                if(index != -1) {
                res.push(item)
              }
              return res
            },[]) 
          }
        } 
        return itemArr
      },
      Seniors() {
        var filters = this.items.reduce((res,item)=>{
            if("yes" == item.senior) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      PhMember() {
        var filters = this.items.reduce((res,item)=>{
            if("yes" == item.ph_member) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      PPPPMember() {
        var filters = this.items.reduce((res,item)=>{
            if("yes" == item.pppp_member) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      SKs() {
        var filters = this.items.reduce((res,item)=>{
            if("SK" == item.category) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      Employed() {
        var filters = this.items.reduce((res,item)=>{
            if("Employed" == item.employment) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      Unemployed() {
        var filters = this.items.reduce((res,item)=>{
            if("Unemployed" == item.employment) {
              res.push(item)
            }
            return res
        }, [])
        console.log(filters)
        return filters
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      dlToCsv(){
            var _date = this.$moment().format("YYYY-MM-DD")
            this.DownloadToCsv("to-dl-table", this.filterText +'-' +_date) 
        },
      formEv(){
        this.form = false
      },
      remarks(data){
          if(data.senior == 'yes') {
            return "Senior Citizen"
          }
          if(data.student == 'yes') {
            return "Student"
          }
          if(data.pwd == 'yes') {
            return "PWD"
          }

      },
      getItems() {
        this.$http.get("data/list").then(response => {
                    response.data.items != null?this.items = response.data.items:this.items=[]
                }).catch(e => {
                 console.log(e.data)
             })
      }
  }
} 
</script>